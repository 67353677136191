<template >
  <div class="app-container">
    <div
      class="item"
      style="border-bottom: 1px solid #e2e2e2; padding-bottom: 15px"
    >
      <div class="item-label">任务单号</div>
      <div class="item-content">{{ dataItem.stageCode }}</div>
    </div>
    <div class="item">
      <div class="item-label">项目名称</div>
      <div class="item-content">{{ dataItem.projectName }}</div>
    </div>

    <div class="item" style="padding-top: 15px">
      <div class="item-label">模具类型</div>
      <div class="item-content">{{ dataItem.mouldType }}</div>
    </div>
    <div class="item">
      <div class="item-label">模具材料</div>
      <div class="item-content">{{ dataItem.mouldMaterial }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dataItem: {
      type: Object,
      // required: true,
      default: {},
    },
  },
};
</script>
<style lang="scss" scoped>
.app-container {
  width: calc(100% - 2.5rem);
  height: auto;
  background: #ffffff;
  border-radius: 0.875rem;
  margin: 1.25rem auto;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: 1.25rem 2.8125rem;
  font-size: 1.625rem;
  div {
    padding: 5px 0;
  }
  .title {
    width: 100%;
    font-size: 13px;
    font-weight: 700;
  }
  .rate {
    font-size: 15px;
    font-weight: 500;
    color: #1782e3;
  }
  ion-item {
    --background: #ffffff;
    --padding-start: 0;
  }
  .item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .item-label {
      width: 60%;
      font-size: 10px;
      opacity: 0.5;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
    }
  }
}
.item-content {
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>