<template>
    <ion-page>
       <Header type="0" title="外协模具信息" v-if="menuRole == 'phone'"> </Header>
    <ion-content scroll-y="true" class="content">
      <div class="searchBox" slot="fixed">
        <div class="searchItem" @click="selectTypeHandler(0)">
          <p
            :class="targetIndex == 0 ? 'searchName targetIndex' : 'searchName'"
          >
            开模
          </p>
          <div
            class="searchSelect"
            v-show="targetIndex == 0"
            :style="{ width: ('全部'.length * 27) / 16 + 'rem' }"
          ></div>
        </div>
        <div class="searchItem" @click="selectTypeHandler(1)">
          <div
            class="searchSelect"
            v-show="targetIndex == 1"
            :style="{ width: ('负责人'.length * 27) / 16 + 'rem' }"
          ></div>
          <p
            :class="targetIndex == 1 ? 'searchName targetIndex' : 'searchName'"
          >
            未开模
          </p>
        </div>
      </div>
      <!-- 无数据  -->
      <div class="no_data" v-if="tableList.length == 0 && !islist">
        <img
          class="no_data_img no_data_top"
          src="@/assets/images/notDate/notSearch.png"
          alt
        />
        <p class="no_data_text">未查询到信息</p>
      </div>
      <div  class="dataList">
        <template-card
       v-for="item in tableList"
       :key="item.id"
       :dataItem="item"
       @click="nav(item)"
      ></template-card>
      </div>
      
      <CardLoading
        v-if="tableList.length <= 0 && islist"
        lodingType="1"
      ></CardLoading>
    </ion-content>
    </ion-page>
</template>
<script>
import Header from "@/components/Header.vue";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import templateCard from "./templateCard.vue";
import {getMouldList} from "@/api/templateInfo/index"
import * as dd from "dingtalk-jsapi";
export default {
  components: {
    Header,
    CardLoading,
    templateCard,
  },
  data() {
    return {
      islist: false,
      tableList: [],
      menuRole:"phone",
      targetIndex:0,
    };
  },
  mounted(){
    this.getList()
      this.menuRole = localStorage.getItem("menuRole");
     if (this.menuRole == "dd") {
      dd.ready(function () {
        dd.biz.navigation.setRight({
          show: false, //控制按钮显示， true 显示， false 隐藏， 默认true
          control: true, //是否控制点击事件，true 控制，false 不控制， 默认false
          text: "发送", //控制显示文本，空字符串表示显示默认文本
          onSuccess: function (result) {
            //如果control为true，则onSuccess将在发生按钮点击事件被回调
            /*
        {}
        */
          },
          onFail: function (err) {},
        });
        dd.biz.navigation.setTitle({
          title: '外协模具信息', //控制标题文本，空字符串表示显示默认文本
          onSuccess: function (result) {
            /*结构
        {
        }*/
          },
          onFail: function (err) {},
        });
      });
    }
  },
  methods: {
    selectTypeHandler(type){
      this.targetIndex = type
    this.getList()
    },
    getList(){
      getMouldList({isDieOpening:this.targetIndex==0?'是':'否'}).then(res=>{
        this.tableList = res.data.data.resultData||[]
      })
    },
    nav(val) {
      this.$router.push({
        path:"/templateAdd",
        query:{
          id:val.id,
          isEdit:val.isEdit
        }
      })
    },
  },
};
</script>
<style lang="scss" scoped>
    .searchBox {
    width: 100%;
    height: px2rem(80);
    line-height: px2rem(80);
    padding: 0 px2rem(10);
    display: flex;
    justify-content: space-around;
    color: #3b3b47;
    font-size: $navigation-font-size;
    background: $default-background;
    .searchItem {
      width: 26%;
      display: flex;
      justify-content: center;
      padding: 0 px2rem(8);
      position: relative;
    }
    .searchItem1 {
      width: 22%;
    }
    .order-select {
      color: $font-segment-title-color;
    }
    p {
      font-size: 14px;
    }
    .ver-line {
      width: px2rem(2);
      display: flex;
      div {
        width: px2rem(2);
        background-color: #c0c0c0;
        height: px2rem(28);
        margin: auto;
      }
    }
    .targetIndex {
      color: $font-segment-title-color;
    }
    .searchLine {
      height: px2rem(30);
      border-right: 0.01rem solid #e4ebf0;
      margin: px2rem(25) 0;
    }
    .searchSelect {
      position: absolute;
      // left: calc(50% - #{px2rem(30)});
      bottom: 0.7rem;
      width: px2rem(60);
      height: px2rem(2);
      background: $font-segment-title-color;
    }
    .iconfont {
      margin-left: px2rem(10);
      font-size: $so-very-little-font-size;
      width: px2rem(25);
    }
  }
  .dataList {
    margin-top: px2rem(100);
  }
</style>